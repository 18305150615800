<template>
  <div class="oscar-columns">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.OscarColumn" :create="openCreateModal">
        <h1 class="title">Colonnes OSCAR</h1>
      </page-header>

      <datatable
        ref="oscarColumnsList"
        :fetch="$Api.OscarColumn.fetchOscarColumns"
        :enableDownload="true"
        :entity="$EntitiesName.OscarColumn"
      >
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
      </datatable>
    </div>

    <modal ref="createOscarColumnModal" @create="createOscarColumn" title="Création d'une colonne OSCAR">
      <div class="columns">
        <div class="column is-half">    
          <ValidationObserver ref="form">
          <text-field label="Nom" data-test="sel-oscar-column-name" v-model="oscarColumn.name" :inline="false" :edit="true" required/>
          </ValidationObserver>
        </div>
      </div>
    </modal>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'oscar-columns',
  data() {
    return {
      oscarColumn :{
        key: ''
      },
    };
  },
    methods: {
    openCreateModal() {
      this.$refs.createOscarColumnModal.open();
    },
    createOscarColumn() {
      // eslint-disable-next-line
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post('/oscar-columns', this.oscarColumn).then((response) => {
          if (response.data.success) {
            this.oscarColumn = {};
            this.$refs.oscarColumnsList.refresh();
            this.$refs.createOscarColumnModal.close();
            this.$router.push({
              name: 'OscarColumn',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },

};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>